<shared-modal [modalTitle]="'Tenants'" [showFooter]="false">
  @if (organizations.length >= 10) {
    <div class="nav-item-menu-item select-list-item" modalHeader>
      <app-search-input (search)="applyFilter($event)"></app-search-input>
    </div>
  }

  <ng-container modalBody>
    @if (organizations) {
      <ul class="scroll-list">
        @for (organization of organizations | appFilter: organizationFilter: 'label'; track organization) {
          <li
            (click)="selectOrganization(organization.name)"
            class="organization-select-item"
            [ngClass]="{ selected: isSelected(organization.name) }"
            >
            {{ organization.label }}
          </li>
        }
      </ul>
    }
    @if (!organizations) {
      <div>
        <p>There are no tenants</p>
      </div>
    }
  </ng-container>
</shared-modal>
